import { EngbersCampaignFlag, EngbersFlagsConfig, MediaType } from '@frontastic-engbers/types/engbers-custom';
import { CampaignFlag, Flag, NewFlag, SaleFlag } from '@frontastic-engbers/types/product/Flag';
import { Campaign } from '@frontastic-engbers/types/product/Campaign';
import { Product } from '@frontastic-engbers/types/product/Product';
import { Variant } from '@frontastic-engbers/types/product/Variant';

export class ProductFlagUtils {
  private readonly SALE_ATTRIBUTE = 'IsSale';
  private readonly NEW_ATTRIBUTE = 'IsNew';

  private configuration: EngbersFlagsConfig | undefined;

  public constructor(configuration?: EngbersFlagsConfig) {
    this.configuration = configuration;
  }

  public static getFlagsConfigFromProjectSettings(settings?: any): EngbersFlagsConfig {
    const { newFlagImage, campaignFlags } = settings;

    return {
      newFlagImage: {
        title: newFlagImage.title,
        media: {
          file: newFlagImage.media?.file,
          name: newFlagImage.media?.name,
          width: newFlagImage.media?.width,
          height: newFlagImage.media?.height,
        },
      },
      campaignFlags:
        campaignFlags?.map((flag: EngbersCampaignFlag) => ({
          ...flag,
          campaignFlagImage: {
            file: flag.campaignFlagImage.media?.file,
            name: flag.campaignFlagImage.media?.name,
            width: flag.campaignFlagImage.media?.width,
            height: flag.campaignFlagImage.media?.height,
          },
        })) ?? [],
    } as EngbersFlagsConfig;
  }

  public static getFlagsFromList(data: string[]): Flag[] {
    const flags: Flag[] = [];

    if (data.includes('sale')) {
      flags.push({ type: 'sale' } as SaleFlag);
    }

    if (data.includes('new')) {
      flags.push({ type: 'new' } as NewFlag);
    }

    return flags;
  }

  public getProductFlags(product: Product): Flag[] {
    const flags = [this.getSaleFlag(product), this.getNewFlag(product), ...this.getCampaignFlags(product)];
    return flags.filter((flag) => flag !== undefined);
  }

  private getSaleFlag(product: Product): SaleFlag | undefined {
    const isSale = product.attributes?.[this.SALE_ATTRIBUTE];
    if (isSale !== '1') {
      return undefined;
    }

    const saleFlag: SaleFlag = { type: 'sale' };
    const saleVariant = this.getSaleVariant(product);

    if (saleVariant !== undefined) {
      saleFlag.discountPerc = (1 - saleVariant.discountedPrice.centAmount / saleVariant.price.centAmount) * 100;
    }

    return saleFlag;
  }

  private getNewFlag(product: Product): NewFlag | undefined {
    const isNew = product.attributes?.[this.NEW_ATTRIBUTE];
    return isNew === '1' ? ({ type: 'new' } as NewFlag) : undefined;
  }

  private getCampaignFlags(product: Product): CampaignFlag[] {
    const campaignFlags: CampaignFlag[] = [];
    const sortedCampaigns = [...(product.campaigns ?? [])];
    sortedCampaigns.sort((a: Campaign, b: Campaign) => b.sortOrder - a.sortOrder);

    for (const campaign of sortedCampaigns) {
      const { campaignId, name, validFrom, validUntil } = campaign;
      const configuration = this.getCampaignConfiguration(campaign);
      const flagImage = this.getCampaignFlagImage(campaign, configuration);

      if (validFrom || validUntil) {
        const date = new Date();

        if ((validFrom && new Date(validFrom) > date) || (validUntil && new Date(validUntil) < date)) {
          continue;
        }
      }

      if (flagImage) {
        campaignFlags.push({
          type: 'campaign',
          campaignId,
          name,
          flagImage,
          description: configuration?.campaignDescription || campaign.description || undefined,
          link: configuration.campaignLink,
        } as CampaignFlag);
      }
    }

    return campaignFlags;
  }

  private getSaleVariant(product: Product): Variant | undefined {
    return product.variants?.find(
      (variant: Variant) =>
        variant.discountedPrice?.centAmount > 0 &&
        variant.price?.centAmount > 0 &&
        variant.price?.centAmount > variant.discountedPrice?.centAmount,
    );
  }

  private getCampaignConfiguration(campaign: Campaign): EngbersCampaignFlag | undefined {
    return this.configuration?.campaignFlags?.find(
      (flag: EngbersCampaignFlag) => flag.campaignId === campaign.campaignId,
    );
  }

  private getCampaignFlagImage(campaign: Campaign, configuration?: EngbersCampaignFlag): MediaType | undefined {
    const title = {
      en_GB: campaign.name,
      de_DE: campaign.name,
    };

    let media = undefined;

    if (campaign.flagImage) {
      media = {
        file: campaign.flagImage,
        name: campaign.name,
      };
    } else if (configuration?.campaignFlagImage) {
      media = configuration?.campaignFlagImage;
    }

    return !media
      ? undefined
      : {
          title,
          media,
        };
  }
}
