import { Translation } from '@frontastic-engbers/helpers/hooks/useI18n';
import { ProductUtils } from '@frontastic-engbers/helpers/utils/productUtils';
import { ProductFlagUtils } from '@frontastic-engbers/helpers/utils/productFlagUtils';
import { EngbersFlagsConfig } from '@frontastic-engbers/types/engbers-custom';
import { Campaign as HitCampaign } from '@frontastic-engbers/types/product/algolia/Campaign';
import { Variant as HitVariant } from '@frontastic-engbers/types/product/algolia/Variant';
import { Product as EcondaProduct } from '@frontastic-engbers/types/product/econda/Product';
import { Category } from '@frontastic-engbers/types/product/Category';
import { Flag, NewFlag } from '@frontastic-engbers/types/product/Flag';
import { Money } from '@frontastic-engbers/types/product/Money';
import { Campaign } from '@frontastic-engbers/types/product/Campaign';
import { Product } from '@frontastic-engbers/types/product/Product';
import { Outfit } from '@frontastic-engbers/types/product/Outfit';
import { HitType } from '@frontastic-engbers/types/product/Hit';
import { Variant } from '@frontastic-engbers/types/product/Variant';

export type ProductMapperConfig = {
  locale?: string;
  currency?: string;
  channel?: string;
  flagsConfig?: EngbersFlagsConfig;
};

export class ProductMapper {
  private static readonly DEFAULT_CHANNEL = 'no-channel';
  private static readonly DEFAULT_LOCALE = 'de-DE';
  private static readonly DEFAULT_CURRENCY = 'EUR';

  private static readonly LOCALE_MAP = {
    de: 'de-DE',
  };

  private static readonly fractionDigits: Record<string, number> = {
    BHD: 3,
    CRC: 0,
    IQD: 3,
    KWD: 3,
    LYD: 3,
  };

  public static econdaProductToProduct(econdaProduct: EcondaProduct, config?: ProductMapperConfig): Product {
    const currency = config?.currency ?? ProductMapper.DEFAULT_CURRENCY;
    const price = ProductMapper.econdaPriceToCentAmount(econdaProduct.oldprice, currency);
    const reducedPrice = ProductMapper.econdaPriceToCentAmount(econdaProduct.price, currency);
    const name = econdaProduct.name.includes('My Favorite')
      ? econdaProduct.name
        .replace(/"/g, '')
        .replace(/(.*)(My Favorite)(.*)/, '$1"$2"$3')
        .trim()
      : econdaProduct.name;

    const product: Product = {
      productId: econdaProduct.id,
      name: name,
      attributes: { DesignerName: econdaProduct.manufacturer },
      url: econdaProduct.deeplink,
      flags: ProductMapper.econdaProductToProductFlags(econdaProduct, config),
      variants: [
        {
          sku: econdaProduct.sku,
          images: [econdaProduct.iconurl],
          price: {
            centAmount: price === 0 ? reducedPrice : price,
            currencyCode: currency,
            fractionDigits: ProductMapper.fractionDigits[currency] ?? 2,
          },
          discountedPrice:
            price === 0
              ? undefined
              : {
                  centAmount: reducedPrice,
                  currencyCode: currency,
                  fractionDigits: ProductMapper.fractionDigits[currency] ?? 2,
                },
        },
      ],
    };

    return product;
  }

  public static econdaProductToProductFlags(econdaProduct: EcondaProduct, config?: ProductMapperConfig): Flag[] {
    const flags: Flag[] = [];

    if (econdaProduct.new === 'true') {
      flags.push({ type: 'new' } as NewFlag);
    }

    return flags;
  }

  public static algoliaHitToProduct(hit: HitType, config?: ProductMapperConfig): Product | Outfit {
    switch (hit.productType?.toLowerCase()) {
    case 'outfit':
      return ProductMapper.algoliaHitToOutfit(hit, config);
    default: // general product
      return ProductMapper.algoliaHitToGeneralProduct(hit, config);
    }
  }

  public static algoliaHitVariantToVariant(hitVariant: HitVariant, config?: ProductMapperConfig): Variant {
    const locale = config?.locale ?? ProductMapper.DEFAULT_LOCALE;
    const currency = config?.currency ?? ProductMapper.DEFAULT_CURRENCY;
    const channel = config?.channel ?? ProductMapper.DEFAULT_CHANNEL;

    return {
      id: hitVariant.id,
      sku: hitVariant.sku,
      price: ProductMapper.getVariantPrice(hitVariant, currency, false),
      discountedPrice: ProductMapper.getVariantPrice(hitVariant, currency, true),
      attributes: hitVariant.attributes,
      images: hitVariant.images,
      isInStock: !!hitVariant.isInStock,
      quantity: ProductMapper.getVariantQuantity(hitVariant, channel),
    };
  }

  public static algoliaHitCampaignToCampaign(campaign: HitCampaign): Campaign {
    const { objectID, identifier, name, description, flagImage, sortOrder, validFrom, validUntil } = campaign;

    return {
      campaignId: identifier ?? objectID ?? '',
      name,
      description,
      flagImage,
      sortOrder: sortOrder ?? 0,
      validFrom,
      validUntil,
    } as Campaign;
  }

  private static getCategories(hit: HitType): Category[] {
    if (!hit.categoryPageId) {
      return [];
    }

    const categories: Category[] = [];
    for (const idx in hit.categoryPageId) {
      categories.push({ name: hit.categoryPageId[idx] });
    }

    return categories;
  }

  private static getVariantQuantity(hitVariant: HitVariant, channel: string): number {
    return Math.max(0, +(hitVariant.inventory?.[channel] ?? 0));
  }

  private static getVariantPrice(hitVariant: HitVariant, currency: string, discountPrice: boolean): Money | undefined {
    const currencies = Object.keys(hitVariant.prices ?? {});
    if (currencies.length === 0) {
      return undefined;
    }

    if (!currencies.includes(currency)) {
      currency = currencies.includes(ProductMapper.DEFAULT_CURRENCY) ? ProductMapper.DEFAULT_CURRENCY : currencies[0];
    }

    const variantPrice: Money = {
      currencyCode: currency,
      fractionDigits: ProductMapper.fractionDigits[currency] ?? 2,
      centAmount: hitVariant.prices?.[currency]?.[discountPrice ? 'discountedValue' : 'value'] ?? 0,
    };

    return variantPrice.centAmount > 0 ? variantPrice : undefined;
  }

  private static getLocalizedValue(data: string | Translation | undefined, locale: string): string | undefined {
    if (!data) {
      return undefined;
    }

    const localizedValue = typeof data === 'string' ? data.trim() : (data[locale] ?? '').trim();
    return localizedValue.length > 0 ? localizedValue : undefined;
  }

  private static econdaPriceToCentAmount(priceString: string, currency: string): number {
    const fractionDigits = ProductMapper.fractionDigits[currency] ?? 2;
    const price = parseFloat(priceString.split(' ')[0].replace(',', '.'));

    return Math.round(price * 10 ** fractionDigits);
  }

  private static algoliaHitToGeneralProduct(hit: HitType, config?: ProductMapperConfig): Product {
    const locale = ProductMapper.getLocale(config);

    const product: Product = {
      productId: hit.objectID ?? undefined,
      productNumber: hit.attributes?.SKU ?? undefined,
      name: ProductMapper.getLocalizedValue(hit.name, locale),
      slug: ProductMapper.getLocalizedValue(hit.slug, locale),
      description: ProductMapper.getLocalizedValue(hit.description, locale),
      attributes: hit.attributes ? hit.attributes : undefined,
      variants: (hit.variants ?? []).map((variant) => ProductMapper.algoliaHitVariantToVariant(variant, config)),
      categories: ProductMapper.getCategories(hit),
      createdAt: typeof hit.createdAt === 'string' ? hit.createdAt : undefined,
      url: ProductMapper.getLocalizedValue(hit.slug, locale),
      position: hit.__position,
    };

    const sortedCampaigns = [...(hit.campaigns ?? [])];
    sortedCampaigns.sort((a, b) => b.sortOrder - a.sortOrder);

    product.campaigns = sortedCampaigns.map((campaign) => ProductMapper.algoliaHitCampaignToCampaign(campaign)) ?? [];
    product.flags = new ProductFlagUtils(config?.flagsConfig).getProductFlags(product);

    return product;
  }

  private static algoliaHitToOutfit(hit: HitType, config?: ProductMapperConfig): Outfit {
    const locale = ProductMapper.getLocale(config);

    const outfit: Outfit = {
      productId: hit.attributes?.SKU ?? hit.variants[0]?.sku ?? undefined,
      name: ProductMapper.getLocalizedValue(hit.name, locale),
      slug: ProductMapper.getLocalizedValue(hit.slug, locale),
      description: ProductMapper.getLocalizedValue(hit.description, locale),
      attributes: hit.attributes ? hit.attributes : undefined,
      variants: (hit.variants ?? []).map((variant) => ProductMapper.algoliaHitVariantToVariant(variant, config)),
      categories: ProductMapper.getCategories(hit),
      createdAt: typeof hit.createdAt === 'string' ? hit.createdAt : undefined,
      url: ProductMapper.getLocalizedValue(hit.slug, locale),
      position: hit.__position,
      products: [],
    };

    if (outfit.attributes?.products && Array.isArray(outfit.attributes.products)) {
      for (const product of outfit.attributes.products) {
        if (ProductUtils.isProduct(product)) {
          outfit.products.push(product);
        } else if (ProductUtils.isProductReference(product)) {
          outfit.products.push({
            productId: product.id,
            variants: [],
          });
        }
      }

      delete outfit.attributes.products;
    }

    return outfit;
  }

  private static getLocale(config?: ProductMapperConfig): string {
    if (config?.locale) {
      return ProductMapper.LOCALE_MAP[config.locale] ?? config.locale;
    }
    return ProductMapper.DEFAULT_LOCALE;
  }
}
